import React, { useState } from "react"
import LanguageMenu from "./language-menu"
import {
  Link,
  useTranslation,
  useI18next,
} from "gatsby-plugin-react-i18next"

const Navbar = () => {
  const [activeMenu, setActiveMenu] = useState(false)
  const { t } = useTranslation()
  const { language } = useI18next()

  const burgerActive = activeMenu ? "is-active" : ""
  const menuActive = activeMenu ? "is-active" : ""

  return (
    <nav
      className="navbar is-transparent"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <Link className="navbar-item" to="/">
          <img src="/images/logo.png" width="112" height="28" alt="logo" />
        </Link>

        <a
          role="button"
          className={`navbar-burger burger ${burgerActive}`}
          aria-label="menu"
          aria-expanded="false"
          tabIndex={0}
          onClick={() => setActiveMenu(!activeMenu)}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div className={`navbar-menu ${menuActive}`}>
        <div className="navbar-end">
          <Link className="navbar-item" to="/pricing">
            {t(`pricing`)}
          </Link>
          <Link className="navbar-item" to="/faq">
            {t(`faq`)}
          </Link>
          {(language === "en-gb" || language === "ja-gb") && (
            <Link className="navbar-item" to="/casestudy">
              {t(`case_study`)}
            </Link>
          )}
          <div className="navbar-item">
            <div className="buttons">
              <Link className="button is-yellow" to="/contact">
                {t(`contact_us`)}
              </Link>
            </div>
          </div>
          <LanguageMenu />
        </div>
      </div>
    </nav>
  )
}

export default Navbar
