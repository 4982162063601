import React from "react"
import { Link, useI18next } from "gatsby-plugin-react-i18next"

const LanguageMenu = () => {
  const { language, originalPath } = useI18next()

  return (
    <>
      {language === "en-gb" ? (
        <Link className="navbar-item" to={originalPath} language={"ja-gb"}>
          日本語
        </Link>
      ) : (
        <Link className="navbar-item" to={originalPath} language={"en-gb"}>
          EN
        </Link>
      )}
    </>
  )
}

export default LanguageMenu
