/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link as GatsbyLink } from "gatsby"
import {
  Link,
  useTranslation,
  useI18next,
} from "gatsby-plugin-react-i18next"
import CookieConsent from "react-cookie-consent"
import { Cookies } from "react-cookie-consent"
import Navbar from "./navbar"

import "./layout.scss"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const { t } = useTranslation()
  const { language } = useI18next()

  Cookies.set(`uketoly-gdpr-google-analytics`, true)
  Cookies.set(`uketoly-gdpr-facebook-pixel`, true)

  return (
    <>
      <div>
        <Navbar />
        <main>{children}</main>
        <footer className="footer">
          <div className="container">
            <div className="columns">
              <div className="column">
                <ul>
                  <li>
                    <Link to="/pricing">{t(`pricing`)}</Link>
                  </li>
                  <li>
                    <Link to="/faq">{t(`faq`)}</Link>
                  </li>
                  {(language === "en-gb" || language === "ja-gb") && (
                    <li>
                      <Link to="/casestudy">{t(`case_study`)}</Link>
                    </li>
                  )}
                  <li>
                    <Link to="/contact">{t(`contact_us`)}</Link>
                  </li>
                </ul>
              </div>
              <div className="column">
                <ul>
                  <li>
                    <GatsbyLink to="legal/terms">{t(`terms`)}</GatsbyLink>
                  </li>
                  <li>
                    <GatsbyLink to="legal/privacy">{t(`privacy`)}</GatsbyLink>
                  </li>
                  <li>
                    <GatsbyLink to="legal/cookie">{t(`cookie`)}</GatsbyLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="content has-text-centered">
            <p>© {new Date().getFullYear()} Uketoly, Built on the road</p>
          </div>
        </footer>
        <CookieConsent
          buttonText="Accept"
          onAccept={() => {
            Cookies.set(`uketoly-gdpr-google-analytics`, true)
            Cookies.set(`uketoly-gdpr-facebook-pixel`, true)
          }}
          buttonClasses="button is-primary"
          containerClasses="notification"
          style={{ borderRadius: 0 }}
        >
          We use cookies to secure, improve, and analyze your site experience
          and use. Read our <Link to="legal/cookie">Cookie Policy</Link> to
          learn more.
        </CookieConsent>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
